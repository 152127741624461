/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-11-04 20:13:28
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-11-04 20:26:28
 * @FilePath: /merchant-frontend/src/core/directive/function/salesChannels.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getSalesChannelsModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setSalesChannelsDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setSalesChannelsDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/sales-channels/";
  result.parentName = language("merchants.salesChannels");
  if (route.path.indexOf("point-of-sale") > -1) {
    strName = "Point of Sale";
    listPath += "point-of-sale";
  } else if (route.path.indexOf("online-stores") > -1) {
    strName = "Online Stores";
    listPath += "online-stores";
  } else if (route.path.indexOf("mobile-applications") > -1) {
    strName = "Mobile Applications";
    listPath += "mobile-applications";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getSalesChannelsDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "store-overview":
      result.currentName = language("common.overview");
      setSalesChannelsDetailName(language, route, result, router);
      break;
    case "pos-information":
    case "store-information":
    case "app-information":
      result.currentName = language("common.information");
      setSalesChannelsDetailName(language, route, result, router);
      break;
  }
  return result;
};
