/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-11-04 20:27:28
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-11-04 20:40:00
 * @FilePath: /merchant-frontend/src/core/directive/function/merchantNetwork.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getMerchantNetworkModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setMerchantNetworkDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setMerchantNetworkDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/merchant-network/";
  result.parentName = "Merchant Network";
  if (route.path.indexOf("group") > -1) {
    strName = "Groups";
    listPath += "groups";
  } else if (route.path.indexOf("leads") > -1) {
    strName = "Leads";
    listPath += "leads";
  } else if (route.path.indexOf("offline-merchants") > -1) {
    strName = "Offline Merchants";
    listPath += "offline-merchants";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getMerchantNetworkDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "offline-merchants-overview":
      result.currentName = language("common.overview");
      setMerchantNetworkDetailName(language, route, result, router);
      break;
    case "group-information":
    case "leads-information":
    case "offline-merchants-information":
      result.currentName = language("common.information");
      setMerchantNetworkDetailName(language, route, result, router);
      break;
    case "offline-merchants-sales-channels":
      result.currentName = language("merchants.salesChannels");
      setMerchantNetworkDetailName(language, route, result, router);
      break;
    case "offline-merchants-contacts":
      result.currentName = language("merchants.contacts");
      setMerchantNetworkDetailName(language, route, result, router);
      break;
    case "offline-merchants-pim":
      result.currentName = language("merchants.pim");
      setMerchantNetworkDetailName(language, route, result, router);
      break;
    case "offline-merchants-distribution":
      result.currentName = language("merchants.distribution");
      setMerchantNetworkDetailName(language, route, result, router);
      break;
    case "offline-merchants-fulfillment":
      result.currentName = language("merchants.fulfillment");
      setMerchantNetworkDetailName(language, route, result, router);
      break;
    case "offline-merchants-settlement":
      result.currentName = "Settlement";
      setMerchantNetworkDetailName(language, route, result, router);
      break;
  }
  return result;
};
