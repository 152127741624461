/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-11-04 19:20:39
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-11-04 19:38:46
 * @FilePath: /merchant-frontend/src/core/directive/function/merchants.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getMerchantsModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setMerchantsDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setMerchantsDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/";
  result.parentName = "";
  if (route.path.indexOf("merchant") > -1) {
    strName = language("merchants.merchants");
    listPath += "merchants";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getMerchantsDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "merchants-overview":
      result.currentName = language("common.overview");
      setMerchantsDetailName(language, route, result, router);
      break;
    case "merchants-information":
      result.currentName = language("common.information");
      setMerchantsDetailName(language, route, result, router);
      break;
    case "merchants-sales-channels":
      result.currentName = language("merchants.salesChannels");
      setMerchantsDetailName(language, route, result, router);
      break;
    case "merchants-contacts":
      result.currentName = language("merchants.contacts");
      setMerchantsDetailName(language, route, result, router);
      break;
    case "merchants-pim":
      result.currentName = language("merchants.pim");
      setMerchantsDetailName(language, route, result, router);
      break;
    case "merchants-distribution":
      result.currentName = language("merchants.distribution");
      setMerchantsDetailName(language, route, result, router);
      break;
    case "merchants-fulfillment":
      result.currentName = language("merchants.fulfillment");
      setMerchantsDetailName(language, route, result, router);
      break;
    case "merchants-settlement":
      result.currentName = "settlement";
      setMerchantsDetailName(language, route, result, router);
      break;
  }
  return result;
};
